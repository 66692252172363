import { calculateAnalyses } from "../formulas/analysisHelper";
import { type IAnalysis, analysisSorter } from "../models/analysis";
import type { AnalysisRow } from "../views/resultUpload/analysisRow";
import { arrayInsert } from "./arrayHelpers";
import { labnumberEquals } from "./labnumberHelper";

export type ParseResult = Papa.ParseResult<{ [key: string]: string | number }>;

export enum FileFormats {
  NIR = "NIR",
  ALS = "ALS",
  AGRI = "AGRI",
  ICP = "ICP",
  PCR = "PCR",
  ELISA = "ELISA",
}

const fileTypes = [
  {
    key: FileFormats.NIR,
    label: "Nir",
  },
  {
    key: FileFormats.ALS,
    label: "Als",
  },
  {
    key: FileFormats.AGRI,
    label: "Agrilab",
  },
  {
    key: FileFormats.ICP,
    label: "ICP",
  },
  {
    key: FileFormats.PCR,
    label: "PCR",
  },
];

export function getFileTypes(enabledTypes: FileFormats[]) {
  return fileTypes.filter((ft) => enabledTypes.includes(ft.key));
}

export function getExtraFields(fileFormat: FileFormats) {
  switch (fileFormat) {
    case FileFormats.PCR:
      return [{ name: "platename", label: "Platta", required: true }];
    default:
      return [];
  }
}

export function idColumn(format: FileFormats) {
  switch (format) {
    case FileFormats.NIR:
      return "Sample Number";
    case FileFormats.ALS:
      return "ELEMENT";
    case FileFormats.ICP:
      return "Sample Name";
    // case FileFormats.PCR:
    //   return "Well";
    default:
      return "id";
  }
}

export interface UploadedRow {
  labnumber: number | string;
  well?: string;
  results: { [key: string]: { value: number; lessThan: boolean } };
}

export function uploadRowFromAgrilab(
  row: Record<string, number | string>,
  weights: Record<string, string | number>,
): UploadedRow {
  const { Journalnr, "Prov-ID": labnumber, ...rest } = row;
  const parsed = Object.fromEntries(
    Object.entries(rest).map(([key, value]) => {
      let parsedValue = value;
      let lessThan = false;
      if (typeof value === "string" && value.startsWith("<")) {
        lessThan = true;
        parsedValue = +value.replace(/[^0-9.,]/g, "").replace(/,/, ".");
        // return [key, {value: +value.replace(/[^0-9.,]/g, "").replace(/,/,"."), lessThan: true}];
      }
      if (weights[key]?.toString().match(/^g\s?\//)) {
        parsedValue = +parsedValue * 1000;
      }
      return [key, { value: +(+parsedValue).toFixed(2), lessThan }];
    }),
  );
  return { labnumber: <number>labnumber, results: parsed };
}

type PapaRow<K extends string> = {
  // [key in K]: number;
  [key: string]: string | number;
};

export function uploadRowsFromPapa<K extends string>(
  result: Papa.ParseResult<PapaRow<K>>,
  idColumn: K,
  fileFormat: FileFormats,
): UploadedRow[] {
  return result.data.map((row) => {
    const { [idColumn]: labnumber, Well: well, ...rest } = row;
    const parsed = Object.fromEntries(
      Object.entries(<{ [key: string]: string | number }>rest).map(
        ([key, value]) => {
          if (typeof value === "string") {
            if (value.startsWith("<")) {
              return [
                key,
                {
                  value: applyCorrection(
                    +value.replace(/[^0-9.,]/g, "").replace(/,/, "."),
                    fileFormat,
                  ),
                  lessThan: true,
                },
              ];
            } else if (fileFormat === FileFormats.PCR && value === "NaN") {
              value = 500;
            }
          }
          return [
            key,
            { value: applyCorrection(+value, fileFormat), lessThan: false },
          ];
        },
      ),
    );
    return { labnumber: labnumber, well: well?.toString(), results: parsed };
  });
}

function applyCorrection(value: number, fileFormat: FileFormats) {
  if (fileFormat === FileFormats.ICP) {
    return +(value / 0.95).toFixed(3);
  }
  return value;
}

function filterICPResult<K extends string>(
  result: Papa.ParseResult<PapaRow<K>>,
): Papa.ParseResult<PapaRow<K>> {
  let data = result.data.filter((d) => d.Type === "Sample");
  data = data.filter((d) => d["Sample Name"] !== "Blank");
  return { ...result, data };
}

function analysisMatcher(
  a: IAnalysis,
  mapping: { csv: string; digero: string },
  fileFormat: FileFormats,
): boolean {
  if (fileFormat === FileFormats.ALS || fileFormat === FileFormats.AGRI) {
    return a.meta?.alsName === mapping.digero;
  }
  if (fileFormat === FileFormats.ICP) {
    return a.code === mapping.digero.toLowerCase();
  }
  return a.code === mapping.digero;
}

const mappings = (
  parsedRow: { [key: string]: unknown },
  format: FileFormats,
) => {
  switch (format) {
    case FileFormats.NIR:
      return [
        { csv: "NDF", digero: "ndf" },
        { csv: "Protein", digero: "protein" },
        { csv: "Energi", digero: "energi" },
        { csv: "Socker", digero: "sugar" },
        { csv: "Glukos", digero: "glucose" },
        { csv: "Fruktos", digero: "fructose" },
        { csv: "Fruktan", digero: "fructan" },
        { csv: "Sukros", digero: "sucrose" },
        { csv: "VOS", digero: "vos" },
        { csv: "Aska", digero: "ash" },
        { csv: "Klorid", digero: "chloride" },
        { csv: "pH", digero: "ph" },
        { csv: "LAF", digero: "lacticacid" },
        { csv: "Hac", digero: "aceticacid" },
        { csv: "ADF", digero: "adf" },
        { csv: "NH3-N", digero: "nh4" },
        { csv: "sCP", digero: "smbrawprotein" },
        { csv: "AshAI", digero: "ash_spa" },
        { csv: "StarchAI", digero: "starch_spa" },
        { csv: "Moisture", digero: "moisture_spa" },
        { csv: "ProteinAI", digero: "protein_spa" },
        { csv: "FatAI", digero: "fat_spa" },
        { csv: "FibreAI", digero: "fibre_spa" },
      ];
    case FileFormats.PCR:
      return [
        { csv: "Cq", digero: "haemonchus" },
        { csv: "Cq", digero: "1" },
      ];
    default:
      return Object.keys(parsedRow)
        .filter((c) => c !== "ELEMENT")
        .map((c) => ({
          csv: c,
          digero: c,
        }));
  }
};

export const applyResult = (
  row: AnalysisRow,
  result: UploadedRow[],
  format: FileFormats,
) => {
  if (!row.analyses) {
    return row;
  }
  const parsedRow = result.find((r) =>
    format === FileFormats.PCR
      ? r.well === row.well
      : labnumberEquals(r.labnumber as number, row.labnumber),
  );
  if (!parsedRow) {
    return row;
  }

  for (const mapping of mappings(parsedRow?.results, format)) {
    const imported = parsedRow?.results[mapping.csv];
    const index = row.analyses.findIndex((a) =>
      analysisMatcher(a, mapping, format),
    );
    if (index >= 0) {
      let analysis = row.analyses[index];
      if (analysis) {
        if (imported?.lessThan) {
          let details = analysis.details || {};
          details = { ...details, minlimit: true };
          analysis = { ...analysis, details: details };
        } else if (analysis.details?.minlimit) {
          let details = analysis.details;
          details = { ...details, minlimit: false };
          analysis = { ...analysis, details: details };
        }
        analysis = { ...analysis, raw: imported?.value, matched: true };
        row.analyses = arrayInsert(row.analyses, analysis, index);
      }
    }
  }

  row.analyses = calculateAnalyses(row.analyses, row.analyses);
  row.analyses = row.analyses.slice().sort(analysisSorter);
  return row;
};

export const filterResult = (result: ParseResult, format: FileFormats) => {
  switch (format) {
    case FileFormats.ICP:
      return filterICPResult(result);
    default:
      return result;
  }
};

export const getHeaders = (
  rows: AnalysisRow[] | undefined,
  showAll: boolean,
) => {
  if (!rows) {
    return [];
  }
  const headers: { id: number; name: string }[] = [];
  for (const row of rows) {
    for (const a of row.analyses.filter((a) => showAll || a.matched)) {
      if (headers.findIndex((h) => h.name === a.name) < 0) {
        headers.push({ id: a.id, name: a.name });
      }
    }
  }
  return headers;
};
