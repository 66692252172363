import {
  type PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import bubbleApi, { type BubbleResult } from "../../apis/bubbleApi";
import messageApi from "../../apis/messageApi";
import type { Message } from "../../models/message";
import type { MessageTag } from "../../models/messageTag";

export interface BubbleNotesSliceState {
  bubble?: BubbleResult["bubble"];
  pending?: boolean;
  tempMessage1: Message;
  tempMessage2: Message;
  tempMessage3: Message;
  startWeight: Message;
  endWeight: Message;
  pepsinMessage: Message;
  saltsyraMessage: Message;
  messages: Message[];
  newMessage: Message;
}

const initialState: BubbleNotesSliceState = {
  tempMessage1: { message: "", messageTags: [{ key: "__temp_", value: 1 }] },
  tempMessage2: { message: "", messageTags: [{ key: "__temp_", value: 2 }] },
  tempMessage3: { message: "", messageTags: [{ key: "__temp_", value: 3 }] },
  startWeight: {
    message: "",
    messageTags: [{ key: "__start_weight", value: 0 }],
  },
  endWeight: { message: "", messageTags: [{ key: "__end_weight", value: 0 }] },
  pepsinMessage: { message: "", messageTags: [{ key: "__pepsin", value: 0 }] },
  saltsyraMessage: {
    message: "",
    messageTags: [{ key: "__saltsyra", value: 0 }],
  },
  messages: [],
  newMessage: { message: "", messageTags: [] },
};

export const openBubble = createAsyncThunk(
  "bubbleNotes/open",
  async (bubbleNumber: string, thunkApi) => {
    const bubble = await bubbleApi.open(bubbleNumber);
    await thunkApi.dispatch(readBubbleMessages(bubble.bubble.id));
    return bubble.bubble;
  },
);

const readBubbleMessages = createAsyncThunk(
  "bubbleNotes/get",
  async (bubbleId: number) => {
    const tags: MessageTag[] = [{ key: "bubble_id", value: bubbleId }];
    return messageApi.read(tags);
  },
);

export const deleteMessage = createAsyncThunk(
  "bubbleNote/delete",
  async (messageId: number) => {
    await messageApi.deleteMessage(messageId);
    return messageId;
  },
);

export const saveMessage = createAsyncThunk<
  Message,
  Message,
  { rejectValue: string }
>("bubbleNotes/save", async (message: Message, thunkApi) => {
  if (!message.messageTags.some((t) => t.key === "bubble_id")) {
    return thunkApi.rejectWithValue("No bubble loaded");
  }
  if (message.id) {
    return messageApi.save(message);
  } else {
    return messageApi.add(message);
  }
});

const addBubbleId = (message: Message, bubbleId: number | undefined) => {
  if (bubbleId === undefined) {
    return message;
  }
  message.messageTags = message.messageTags.filter(
    (t) => t.key !== "bubble_id",
  );

  message.messageTags.push({ key: "bubble_id", value: bubbleId });
  return message;
};

const resetMessage = (message: Message) => {
  const newMessage = {
    message: "",
    messageTags: message.messageTags
      .filter((t) => t.key !== "bubble_id")
      .map((t) => ({ key: t.key, value: t.value })),
  };
  return newMessage;
};

const bubbleNotesSlice = createSlice({
  name: "bubbleNotes",
  initialState,
  reducers: {
    updateTemperature1(state, action: PayloadAction<string>) {
      state.tempMessage1.message = action.payload;
    },
    updateTemperature2(state, action: PayloadAction<string>) {
      state.tempMessage2.message = action.payload;
    },
    updateTemperature3(state, action: PayloadAction<string>) {
      state.tempMessage3.message = action.payload;
    },
    updateStartWeight(state, action: PayloadAction<string>) {
      state.startWeight.message = action.payload;
    },
    updateEndWeight(state, action: PayloadAction<string>) {
      state.endWeight.message = action.payload;
    },
    updatePepsin(state, action: PayloadAction<string>) {
      state.pepsinMessage.message = action.payload;
    },
    updateSaltsyra(state, action: PayloadAction<string>) {
      state.saltsyraMessage.message = action.payload;
    },
    updateNewMessage(state, action: PayloadAction<string>) {
      state.newMessage.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(openBubble.pending, (state) => {
        state.tempMessage1 = resetMessage(state.tempMessage1);
        state.tempMessage2 = resetMessage(state.tempMessage2);
        state.tempMessage3 = resetMessage(state.tempMessage3);
        state.startWeight = resetMessage(state.startWeight);
        state.endWeight = resetMessage(state.endWeight);
        state.pepsinMessage = resetMessage(state.pepsinMessage);
        state.saltsyraMessage = resetMessage(state.saltsyraMessage);
        state.newMessage = resetMessage(state.newMessage);
        state.messages = [];
        state.pending = true;
      })
      .addCase(openBubble.fulfilled, (state, action) => {
        state.pending = false;
        state.bubble = action.payload;
        addBubbleId(state.tempMessage1, state.bubble.id);
        addBubbleId(state.tempMessage2, state.bubble.id);
        addBubbleId(state.tempMessage3, state.bubble.id);
        addBubbleId(state.startWeight, state.bubble.id);
        addBubbleId(state.endWeight, state.bubble.id);
        addBubbleId(state.pepsinMessage, state.bubble.id);
        addBubbleId(state.saltsyraMessage, state.bubble.id);
        addBubbleId(state.newMessage, state.bubble.id);
      })
      .addCase(readBubbleMessages.fulfilled, (state, action) => {
        for (const message of action.payload) {
          if (
            message.messageTags.some(
              (t) => t.key === "__temp_" && t.value === 1,
            )
          ) {
            state.tempMessage1 = message;
          } else if (
            message.messageTags.some(
              (t) => t.key === "__temp_" && t.value === 2,
            )
          ) {
            state.tempMessage2 = message;
          } else if (
            message.messageTags.some(
              (t) => t.key === "__temp_" && t.value === 3,
            )
          ) {
            state.tempMessage3 = message;
          } else if (
            message.messageTags.some((t) => t.key === "__start_weight")
          ) {
            state.startWeight = message;
          } else if (
            message.messageTags.some((t) => t.key === "__end_weight")
          ) {
            state.endWeight = message;
          } else if (message.messageTags.some((t) => t.key === "__pepsin")) {
            state.pepsinMessage = message;
          } else if (message.messageTags.some((t) => t.key === "__saltsyra")) {
            state.saltsyraMessage = message;
          } else {
            state.messages.push(message);
          }
        }
      })
      .addCase(saveMessage.fulfilled, (state, action) => {
        const message = action.payload;
        if (
          message.messageTags.some((t) => t.key === "__temp_" && t.value === 1)
        ) {
          state.tempMessage1 = message;
        } else if (
          message.messageTags.some((t) => t.key === "__temp_" && t.value === 2)
        ) {
          state.tempMessage2 = message;
        } else if (
          message.messageTags.some((t) => t.key === "__temp_" && t.value === 3)
        ) {
          state.tempMessage3 = message;
        } else if (
          message.messageTags.some((t) => t.key === "__start_weight")
        ) {
          state.startWeight = message;
        } else if (message.messageTags.some((t) => t.key === "__end_weight")) {
          state.endWeight = message;
        } else if (message.messageTags.some((t) => t.key === "__pepsin")) {
          state.pepsinMessage = message;
        } else if (message.messageTags.some((t) => t.key === "__saltsyra")) {
          state.saltsyraMessage = message;
        } else {
          state.messages.push(message);
          state.newMessage = addBubbleId(
            { message: "", messageTags: [] },
            state.bubble?.id,
          );
        }
      })
      .addCase(deleteMessage.fulfilled, (state, action) => {
        state.messages = state.messages.filter((m) => m.id !== action.payload);
      });
  },
});

export const {
  updateTemperature1,
  updateTemperature2,
  updateTemperature3,
  updateStartWeight,
  updateEndWeight,
  updatePepsin,
  updateSaltsyra,
  updateNewMessage,
} = bubbleNotesSlice.actions;

export default bubbleNotesSlice.reducer;
