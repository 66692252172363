import type { Theme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    darkgreen: Palette["primary"];
  }

  interface PaletteOptions {
    darkgreen?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    darkgreen: true;
  }
}

export const addDarkGreen = (theme: Theme) =>
  theme.palette.augmentColor({
    color: { main: "#006400" },
    name: "darkgreen",
  });
