import {
  type PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import invoiceApi from "../apis/invoiceApi";
import type { InvoiceBasis, InvoiceOptions } from "../models/invoice";

export interface InvoiceSliceState {
  rows: InvoiceBasis[];
  loading: boolean;
  invoicing: boolean;
}

const initialState: InvoiceSliceState = {
  rows: [],
  loading: false,
  invoicing: false,
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    select(state, action: PayloadAction<number>) {},
    updateInvoiceBasis(state, action: PayloadAction<InvoiceBasis>) {
      const index = state.rows.findIndex((row) => row.id === action.payload.id);
      if (index > -1) {
        state.rows[index] = action.payload;
      }
      return state;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(loadInvoices.pending, (state) => {
        state.rows = [];
        state.loading = true;
      })
      .addCase(loadInvoices.rejected, (state) => {
        state.rows = [];
        state.loading = false;
      })
      .addCase(loadInvoices.fulfilled, (state, action) => {
        state.rows = action.payload;
        state.loading = false;
      })
      .addCase(invoice.pending, (state) => {
        state.invoicing = true;
      })
      .addCase(invoice.rejected, (state) => {
        state.invoicing = false;
      })
      .addCase(invoice.fulfilled, (state, action) => {
        state.rows = state.rows.map((row) => {
          const updatedRow = action.payload.find((r) => r.id === row.id);
          return updatedRow ?? row;
        });
      })
      .addCase(getStatus.fulfilled, (state, action) => {
        state.rows = state.rows.map((row) => {
          const updatedRow = action.payload.find((r) => r.id === row.id);
          return updatedRow ?? row;
        });
      }),
});
export const { updateInvoiceBasis } = invoiceSlice.actions;
export default invoiceSlice.reducer;

export const loadInvoices = createAsyncThunk(
  "invoices/load",
  async (options: InvoiceOptions, thunkApi) => {
    const result = await invoiceApi.load(options);
    try {
      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const invoice = createAsyncThunk(
  "invoices/invoice",
  async (invoices: InvoiceBasis[], thunkApi) => {
    const result = await invoiceApi.send(invoices);
    return result;
  },
);
export const getStatus = createAsyncThunk(
  "invoice/status",
  async (invoices: InvoiceBasis[], thunkApi) => {
    return await invoiceApi.getStatus(invoices);
  },
);
