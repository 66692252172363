import { createFormula, fromFormulaName } from "./analysisFormulaUtils";

const dependencies = ["ctrl"] as const;

export const elisaCtrl = createFormula(
  dependencies,
  (analysis, { ctrl }, valid, _blocked) => {
    if (!ctrl?.raw || !analysis.raw) {
      return { value: 0 };
    }
    let blocked = false;
    const avg = (+ctrl.raw + +analysis.raw) / 2.0;
    const diff = Math.abs(avg - analysis.raw);
    if (diff / avg > 0.25) {
      blocked = true;
    }

    return { value: +analysis.raw, blocked };
  },
  (analysis, analyses) => {
    const ctrl = fromFormulaName(analyses, <string>analysis.meta?.ctrl);
    return { ctrl };
  },
);
