import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import bubbleApi, {
  type BubbleResult,
  type Bubble,
  type ResultStatus,
} from "../../apis/bubbleApi";
import type { RootState } from "../../reducers/rootReducer";

export interface BubbleSliceState {
  pending: boolean;
  analyses?: { labnumber: string; weight: number }[];
  bubble?: Bubble;
  status?: ResultStatus;
}

const initialState: BubbleSliceState = {
  pending: false,
  analyses: [],
  bubble: undefined,
  status: undefined,
};

export const openBubble = createAsyncThunk(
  "bubble/open",
  async (bubbleNumber: string) => {
    return bubbleApi.open(bubbleNumber);
  },
);

export const closeBubble = createAsyncThunk<
  Bubble,
  void,
  {
    state: RootState;
  }
>("bubble/close", async (_params, thunkApi) => {
  const state = thunkApi.getState();
  const bubble = state.bubble.bubble;
  const labnumbers = state.bubble.analyses?.map((a) => a.labnumber);
  if (!bubble || !labnumbers) {
    return thunkApi.rejectWithValue("");
  }
  return bubbleApi.close(bubble.id, labnumbers);
});

export const addToBubble = createAsyncThunk<
  BubbleResult,
  number,
  {
    state: RootState;
  }
>("bubble/bubble", async (labnumber: number, thunkApi) => {
  const state = thunkApi.getState();
  const bubble = state.bubble.bubble;
  if (!bubble) {
    return thunkApi.rejectWithValue("");
  }
  const labnumbers = state.bubble.analyses?.map((a) => a.labnumber) || [];
  return bubbleApi.bubble(bubble.id, labnumber, labnumbers);
});

const bubbleSlice = createSlice({
  name: "bubbles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(openBubble.pending, (state) => {
        state.pending = true;
      })
      .addCase(openBubble.fulfilled, (state, action) => {
        state.pending = false;
        state.bubble = action.payload.bubble;
        state.analyses = action.payload.analyses || [];
      })
      .addCase(openBubble.rejected, (state, action) => {
        state.pending = false;
      })
      .addCase(closeBubble.pending, (state) => {
        state.pending = true;
      })
      .addCase(closeBubble.fulfilled, (state, action) => {
        state.pending = false;
      })
      .addCase(closeBubble.rejected, (state, action) => {
        state.pending = false;
      })
      .addCase(addToBubble.pending, (state) => {
        state.pending = true;
      })
      .addCase(addToBubble.fulfilled, (state, action) => {
        state.pending = false;
        if (action.payload.analyses) {
          state.analyses = action.payload.analyses;
        }
        state.status = action.payload.result;
      })
      .addCase(addToBubble.rejected, (state, action) => {
        state.pending = false;
      });
  },
});

export default bubbleSlice.reducer;
