import { blocking, type Formula, validate } from "./analysisFormulaUtils";
import { elisaCtrl } from "./elisaCtrl";
import type { IAnalysis } from "../models/analysis";
import { omd } from "./omd";
import { ostertagia } from "./ostertagia";
import { metEnergyPerkg } from "./metEnergyPerkg";
import { multiply } from "./common";
import { smbCarbGrov } from "./smbCarbGrov";
import { aatGrov } from "./aatGrov";
import { pbvGrov } from "./pbvGrov";
import { omsEnergyHest } from "./omsEnergyHorse";
import { omsEnergyIdis } from "./omsEnergyIdis";
import { smbProtGrov } from "./smbProtGrov";
import { protEnergyQuota } from "./protEnergyQuota";
import {
  fructan,
  fructose,
  glucose,
  sucrose,
  sucroseCorr,
  sugar,
} from "./sugar";
import { nh4n } from "./nh4n";
import { quotaCaP } from "./quotaCaP";
import { pcr } from "./pcr";
import { cab } from "./cab";
import { scp } from "./scp";
import { percent } from "./percent";
import { epg } from "./epg";
import { drysubstSpa } from "./drysubstSpa";
import { gPerkgDrysubst } from "./gPerkgDrysubst";
import { drysubst } from "./drysubst";
import { butyricacid } from "./butyricacid";
import { dryWeight, dryWeightCorr } from "./dryWeight";
import { spaceweight } from "./spaceweight";
import type { AnalysisType } from "../models/analysisGroup";

export type Formulas = keyof typeof formulas;

const formulas = {
  ostertagia,
  omd,
  elisaCtrl,
  metEnergyPerkg,
  multiply,
  smbCarbGrov,
  aatGrov,
  pbvGrov,
  omsEnergyHest,
  omsEnergyIdis,
  smbProtGrov,
  protEnergyQuota,
  sugar,
  fructan,
  fructose,
  glucose,
  sucrose,
  pcr,
  cab,
  scp,
  nh4n,
  QCaP: quotaCaP,
  epg,
  percent,
  dryweight: dryWeight,
  dryweight2: dryWeightCorr,
  spaceweight,
  drysubst,
  drysubst_spa: drysubstSpa,
  "g/kg_drysubst": gPerkgDrysubst,
  butyricacid,
  sucroseCorr,
};

export const executeFormula = (
  formula: keyof typeof formulas,
  analysis: IAnalysis,
  analyses: readonly IAnalysis[],
  otherAnalyses: readonly IAnalysis[],
) => {
  const f = formulas[formula];
  if (!f) {
    return { value: analysis.raw };
  }
  const params = Object.fromEntries(
    f.dependencies.map((p) => [
      p,
      analyses.find((a) => a.meta?.formulaName === p) ??
        otherAnalyses.find((a) => a.meta?.formulaName === p),
    ]),
  ) as Record<(typeof f.dependencies)[number], IAnalysis | undefined>;
  const extraParams = f.dependenciesLookup?.(analysis, analyses);
  const blocked = blocking(Object.values(params));
  const valid = validate(analysis, Object.values(params));
  const result = f.formula(
    analysis,
    { ...params, ...extraParams },
    valid,
    blocked,
  );
  return result;
};

export const getFormula = (analysis: IAnalysis | AnalysisType | undefined) => {
  if (!analysis) {
    return undefined;
  }
  return formulas[analysis.meta?.formula as keyof typeof formulas];
};

export const lookupDependencies = (
  formula: Formula<readonly string[]>,
  visited = new Set<string>(),
): string[] => {
  if (!formula.dependencies) {
    return [];
  }
  return formula.dependencies.slice();
  // return formula.dependencies.flatMap((d) => {
  //   if (visited.has(d)) {
  //     return [];
  //   }
  //   visited.add(d);
  //   const nextFormula = formulas[d as keyof typeof formulas];
  //   return nextFormula
  //     ? lookupDependencies(nextFormula, visited).concat(d)
  //     : [d];
  // });
};
