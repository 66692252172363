import { compareVersions } from "compare-versions";
import {
  type FC,
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { useInterval } from "usehooks-ts";
import appApi from "../apis/appApi";
import { useWarning } from "../hooks/notificationHooks";
import type { PingResult } from "../models/pingResult";
import { UserContext } from "./userContext";

type ApplicationContextType = {
  pingResult?: PingResult;
  isPinging?: boolean;
  showRefresh?: boolean;
  pingStatus?: "success" | "fail";
  showChanges: boolean;
  setShowChanges: (value: boolean) => void;
  emergencyStop: (stop: boolean, reason?: string) => void;
};
export const ApplicationContext = createContext<ApplicationContextType>({
  setShowChanges: () => {},
  emergencyStop: () => {},
  showChanges: false,
});
const PING_INTERVAL = 30000;

export const ApplicationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [pingResult, setPingResult] = useState<PingResult | undefined>();
  const [isPinging, setIsPinging] = useState(false);
  const [showRefresh, setShowRefresh] = useState(false);
  const [pingStatus, setPingStatus] = useState<
    "success" | "fail" | undefined
  >();
  const [showChanges, setShowChanges] = useState(false);
  const [pingInterval, setPingInterval] = useState(0);
  const addWarning = useWarning();
  const { logout } = useContext(UserContext);

  const ping = useCallback(async () => {
    setIsPinging(true);
    try {
      const result = await appApi.ping();
      const prevVersion = pingResult?.semver;
      const version = result.semver;
      if (prevVersion && version && compareVersions(version, prevVersion) > 0) {
        setShowRefresh(true);
      }
      if (result.validToken === false) {
        logout();
      }
      setIsPinging(false);
      setPingResult(result);
      setPingStatus("success");
    } catch (error) {
      setIsPinging(false);
      setPingStatus("fail");
    }
  }, [pingResult?.semver, logout]);
  useInterval(() => {
    ping();
    setPingInterval(PING_INTERVAL);
  }, pingInterval);
  const emergencyStop = async (stop: boolean, reason?: string) => {
    if (stop) {
      addWarning("Epostutskick har nu stoppats");
      const result = await appApi.stopEmail(reason ?? "");
      return setPingResult(result);
    }
    const result = await appApi.startEmail();
    setPingResult(result);
  };
  const context = {
    pingResult,
    isPinging,
    showRefresh,
    pingStatus,
    showChanges,
    ping,
    setShowChanges,
    emergencyStop,
  };
  return (
    <ApplicationContext.Provider value={context}>
      {children}
    </ApplicationContext.Provider>
  );
};
