import { createFormula } from "./analysisFormulaUtils";

export const multiply = createFormula([], (analysis) => {
  const multiplyBy = analysis.meta?.formulaOptions?.multiply;
  if (!analysis.raw || !multiplyBy) {
    return { value: 0 };
  }

  return { value: +analysis.raw * multiplyBy };
});
