import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["pos1", "pos2", "neg1", "neg2"] as const;

export const ostertagia = createFormula(
  dependencies,
  (analysis, { pos1, pos2, neg1, neg2 }, valid, blocked) => {
    if (!pos1?.raw || !pos2?.raw || !neg1?.raw || !neg2?.raw || !analysis.raw) {
      return { value: 0, blocked };
    }
    const pos = (+pos1.raw + +pos2.raw) / 2.0;
    const neg = (+neg1.raw + +neg2.raw) / 2.0;
    const value = (+analysis.raw - neg) / (pos - neg);
    return { value, blocked };
  },
);
