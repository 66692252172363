import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import auditApi from "../apis/auditApi";
import customerApi from "../apis/customerApi";
import type { Audit } from "../models/audit";
import type { Customer } from "../models/customer";
import { creatingCustomer } from "./registrationSlice";

export interface CustomerSliceState {
  searchTerm: string;
  searchResults: readonly Customer[];
  count: number;
  searching: boolean;
  updatingMemberships: boolean;
  audits: { [customerId: number]: Audit<Customer>[] };
}

const initialState: CustomerSliceState = {
  searchTerm: "",
  searchResults: [],
  count: 0,
  searching: false,
  updatingMemberships: false,
  audits: {},
};

export const loadBlacklisted = createAsyncThunk(
  "customer/fetchBlacklist",
  async () => {
    return customerApi.loadBlacklist();
  },
);

export const loadBroken = createAsyncThunk("customer/fetchBroken", async () => {
  return customerApi.getBroken();
});

export const searchCustomer = createAsyncThunk(
  "customer/search",
  async (
    params: { searchTerm: string; page: number; mode: "standard" | "quick" },
    thunkApi,
  ) => {
    try {
      const result =
        params.mode === "standard"
          ? await customerApi.fullSearch(params.searchTerm, params.page)
          : await customerApi.quickSearch(params.searchTerm, params.page);
      const customers = result;
      return { ...params, customers };
    } catch (error) {
      return thunkApi.rejectWithValue(params.searchTerm);
    }
  },
);

export const saveCustomer = createAsyncThunk(
  "customer/save",
  async (customer: Customer, thunkApi) => {
    if (customer.id < 0) {
      thunkApi.dispatch(creatingCustomer(customer));
      const result = await customerApi.create(customer);
      try {
        const newCustomer = result;
        return { customer: newCustomer, tempId: customer.id };
      } catch (error) {
        return thunkApi.rejectWithValue("");
      }
    } else {
      const result = await customerApi.update(customer);
      try {
        const updatedCustomer = result;
        return { customer: updatedCustomer };
      } catch (error) {
        return thunkApi.rejectWithValue(customer);
      }
    }
  },
);

export const resetGod = createAsyncThunk(
  "customer/resetGod",
  async (customer: Customer, thunkApi) => {
    try {
      return await customerApi.resetGod(customer);
    } catch (error) {
      return thunkApi.rejectWithValue("");
    }
  },
);

export const updateMemberships = createAsyncThunk(
  "customer/updateMemberships",
  async (customer: Customer, thunkApi) => {
    try {
      const result = await customerApi.updateMemberships(customer);
      const updatedCustomer = result;
      return updatedCustomer;
    } catch (error) {
      return thunkApi.rejectWithValue(customer);
    }
  },
);

export const fetchCustomerAudits = createAsyncThunk(
  "customer/fetchAudits",
  async (customer: Customer, thunkApi) => {
    const audits = await auditApi.fetch<Customer>("customer2", customer.id);
    try {
      return { customer, audits };
    } catch (error) {
      return thunkApi.rejectWithValue(customer);
    }
  },
);

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(loadBlacklisted.pending, (state, action) => {
        state.searching = true;
        state.searchResults = [];
      })
      .addCase(loadBlacklisted.fulfilled, (state, action) => {
        state.searchResults = action.payload;
        state.searching = false;
      })
      .addCase(loadBlacklisted.rejected, (state, action) => {
        state.searchResults = [];
        state.searching = false;
      })
      .addCase(loadBroken.pending, (state, action) => {
        state.searching = true;
        state.searchResults = [];
      })
      .addCase(loadBroken.fulfilled, (state, action) => {
        state.searching = false;
        state.searchResults = action.payload;
      })
      .addCase(loadBroken.rejected, (state, action) => {
        state.searching = false;
        state.searchResults = [];
      })
      .addCase(searchCustomer.pending, (state, action) => {
        state.searchTerm = action.meta.arg.searchTerm;
        state.searchResults = [];
        state.searching = true;
      })
      .addCase(searchCustomer.rejected, (state, action) => {
        if (action.error === state.searchTerm) {
          state.searchResults = [];
          state.searching = false;
        }
      })
      .addCase(searchCustomer.fulfilled, (state, action) => {
        if (action.payload.searchTerm === state.searchTerm) {
          state.searchResults = action.payload.customers.customers;
          state.count = action.payload.customers.count;
          state.searching = false;
        }
      })
      .addCase(saveCustomer.fulfilled, (state, action) => {
        const customer = action.payload.customer;
        const customers = state.searchResults;
        if (action.payload.tempId === undefined) {
          const cIndex = customers.findIndex((c) => c.id === customer.id);
          if (cIndex >= 0) {
            customers[cIndex] = customer;
          }
          state.searchResults = customers;
        } else {
          state.searchResults.push(customer);
        }
      })
      .addCase(resetGod.fulfilled, (state, action) => {
        const customer = action.payload;
        const customers = state.searchResults;
        const cIndex = customers.findIndex((c) => c.id === customer.id);
        if (cIndex >= 0) {
          customers[cIndex] = customer;
        }
        state.searchResults = customers;
      })
      .addCase(fetchCustomerAudits.fulfilled, (state, action) => {
        state.audits[action.payload.customer.id] = action.payload.audits;
      })
      .addCase(updateMemberships.pending, (state) => {
        state.updatingMemberships = true;
      })
      .addCase(updateMemberships.rejected, (state) => {
        state.updatingMemberships = false;
      })
      .addCase(updateMemberships.fulfilled, (state, action) => {
        const customer = action.payload;
        const customers = state.searchResults;
        const cIndex = customers.findIndex((c) => c.id === customer.id);
        if (cIndex >= 0) {
          customers[cIndex] = customer;
        }
        state.searchResults = customers;
        state.updatingMemberships = false;
      }),
});

export default customerSlice.reducer;
