import type { AnalysisTypeMeta } from "./analysisType";
import type { AnimalMeta } from "./animal";

export interface IAnalysis {
  id: number;
  type: string;
  name: string;
  raw?: number;
  calc?: number;
  meta?: AnalysisTypeMeta;
  details:
    | Readonly<{
        [key: string]:
          | boolean
          | string
          | { [key: string]: boolean }
          | undefined;
      }>
    | undefined;
  multiple?: number;
  percentOfValue?: number;
  percentOf?: string;
  code: string;
  productMeta?: AnimalMeta;
  max?: number;
  min?: number;
  // tslint:disable-next-line:no-reserved-keywords
  default: null | number;
  canHide?: boolean;
  hidden?: boolean;
  //safeName: string;
  //sortOrder?: number;
  row?: number;
  rowOrder?: number;
  typeId: number;
  extra: boolean;
  savedAt?: string;
  // Only used for file-upload
  matched?: boolean;
}

export type Analysis = Readonly<IAnalysis>;

export function value(analysis: Analysis, includeUnit = false): string {
  let val = "";
  let unit = "";
  if (analysis.meta?.calculated && (analysis.calc || analysis.calc === 0)) {
    val = analysis.calc.toString();
    if (analysis.type === "numerical" && analysis.raw) {
      val = `${val}(${rawValue(analysis)})`;
    }
  } else if (
    (analysis.type === "numerical" || analysis.type === "percent") &&
    analysis.meta?.renderMode === "none" &&
    (analysis.calc || analysis.calc === 0)
  ) {
    val = analysis.calc.toString();
  } else {
    val = analysis.raw != null ? analysis.raw.toString() : "";
  }
  if (includeUnit && !analysis.meta?.hideUnit && analysis.meta?.unit) {
    unit = ` ${analysis.meta.unit}`;
  }
  return val + unit;
}

function rawValue(analysis: Analysis): string {
  let val = "";
  val = analysis.raw != null ? analysis.raw.toString() : "";
  return val;
}

export function canIncrement(analysis: Analysis) {
  return analysis.type === "numerical" || analysis.type === "percent";
}

export function initialize(analysis: Analysis) {
  return { ...analysis, raw: 0 };
}

export function analysisSorter(a: Analysis, b: Analysis) {
  if (a.extra && !b.extra) {
    return 1;
  }
  if (b.extra && !a.extra) {
    return -1;
  }
  if ((a.row ?? 0) - (b.row ?? 0) !== 0) {
    return (a.row ?? 0) - (b.row ?? 0);
  }
  if ((a.rowOrder ?? 0) - (b.rowOrder ?? 0) !== 0) {
    return (a.rowOrder ?? 0) - (b.rowOrder ?? 0);
  }
  if (a.typeId - b.typeId !== 0) {
    return a.typeId - b.typeId;
  }
  return 0;
}
