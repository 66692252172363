import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["energy"] as const;
export const omsEnergyHest = createFormula(
  dependencies,
  (analysis, { energy }, valid, blocked) => {
    if (!energy?.raw) {
      return { value: 0 };
    }
    return { value: 1.12 * energy.raw - 1.2, blocked };
  },
);
