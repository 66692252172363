import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["drysubst"] as const;
export const gPerkgDrysubst = createFormula(
  dependencies,
  (analysis, { drysubst }, valid, blocked) => {
    if (!drysubst?.calc) {
      return { value: 0, blocked };
    }
    return { value: drysubst?.calc / 10, blocked };
  },
);
