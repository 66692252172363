import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["protein", "smbCarbGrov"] as const;
export const pbvGrov = createFormula(
  dependencies,
  (analysis, { protein, smbCarbGrov }, valid, blocked) => {
    if (!protein?.raw || !smbCarbGrov?.calc) {
      return { value: 0 };
    }
    const EPD = 0.8;
    const B49 = 0.179;
    return {
      value: protein.raw * EPD - +smbCarbGrov.calc * B49,
      blocked,
    };
  },
);
