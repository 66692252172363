import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["protein"] as const;

export const nh4n = createFormula(dependencies, (analysis, { protein }) => {
  if (!protein?.raw || !analysis.raw) {
    return { value: 0 };
  }
  return { value: (analysis.raw / (protein.raw / 6.25)) * 1000 };
});
