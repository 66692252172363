import { createFormula, fromFormulaName } from "./analysisFormulaUtils";

const dependencies = ["percentOf"] as const;

export const percent = createFormula(
  dependencies,
  (analysis, { percentOf }) => {
    let percentOfValue = analysis.percentOfValue;
    const blocked = false;
    if (!percentOfValue && percentOf) {
      percentOfValue = percentOf.raw;
    }
    if (!percentOfValue) {
      return { value: 0, blocked };
    }
    if (percentOfValue <= 0) {
      return { value: 0, blocked };
    }
    if (analysis.raw == null) {
      return { value: 0, blocked };
    }
    const percent = +((analysis.raw / percentOfValue) * 100).toFixed();
    return { value: percent, blocked };
  },
  (analysis, analyses) => {
    const percentOf = analysis.percentOf
      ? fromFormulaName(analyses, analysis.percentOf)
      : undefined;
    return { percentOf };
  },
);
