import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import productsApi from "../apis/productsApi";
import { useAuthenticated } from "../hooks/authHooks";
import type { AnalysisType, IAnalysisGroup } from "../models/analysisGroup";
import type { IProduct } from "../models/product";
import type { IProductFamily } from "../models/productFamily";
import type { ProductPackage } from "../models/productPackage";

type ProductContextType = {
  products: IProduct[];
  productFamilies: IProductFamily[];
  productPackages: { [key: number]: ProductPackage[] };
  analysisGroups: IAnalysisGroup[];
  analysisTypes: AnalysisType[];
};

export const ProductContext = createContext<ProductContextType>({
  products: [],
  productFamilies: [],
  productPackages: {},
  analysisGroups: [],
  analysisTypes: [],
});

export const ProductProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [productFamilies, setProductFamilies] = useState<IProductFamily[]>([]);
  const [productPackages, setProductPackages] = useState<{
    [key: number]: ProductPackage[];
  }>({});
  const [analysisGroups, setAnalysisGroups] = useState<IAnalysisGroup[]>([]);
  const [analysisTypes, setAnalysisTypes] = useState<AnalysisType[]>([]);
  const authenticated = useAuthenticated();

  const fetchProducts = useCallback(async () => {
    const products = await productsApi.getProducts();
    setProducts(products);
  }, []);
  const fetchProductFamilies = useCallback(async () => {
    const productFamilies = await productsApi.getProductFamilies();
    setProductFamilies(productFamilies);
  }, []);
  const fetchProductPackages = useCallback(async () => {
    const productPackages = await productsApi.getProductPackages();
    setProductPackages(productPackages);
  }, []);
  const fetchAnalysisGroups = useCallback(async () => {
    const analysisGroups = await productsApi.getAnalysisGroups();
    setAnalysisGroups(analysisGroups);
  }, []);
  const fetchAnalysisTypes = useCallback(async () => {
    const analysisTypes = await productsApi.getAnalysisTypes();
    setAnalysisTypes(analysisTypes);
  }, []);
  useEffect(() => {
    if (!authenticated) return;

    fetchProducts();
    fetchProductFamilies();
    fetchProductPackages();
    fetchAnalysisGroups();
    fetchAnalysisTypes();
  }, [
    authenticated,
    fetchProducts,
    fetchProductFamilies,
    fetchProductPackages,
    fetchAnalysisGroups,
    fetchAnalysisTypes,
  ]);

  return (
    <ProductContext.Provider
      value={{
        products,
        productFamilies,
        productPackages,
        analysisGroups,
        analysisTypes,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
