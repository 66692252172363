export function getErrorMessage(error: unknown) {
  if (error instanceof Error) {
    return error.message;
  }
  return String(error);
}

export function getExceptionMessage(error: unknown) {
  type ErrorResponse = {
    response?: {
      data?: {
        exception?: string;
      };
    };
  };

  const errorResponse = (error as ErrorResponse)?.response?.data || {};
  const errorMessage = errorResponse.exception || getErrorMessage(error);
  return errorMessage;
}
