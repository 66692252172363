import type { DateTime } from "luxon";
import type { Customer } from "./customer";
import {
  type Journal,
  type RegistrationJournal,
  type ResultJournal,
  createJournal,
  isValid as isValidJournal,
} from "./journal";
import type { MailJob } from "./mailJob";
import type { Message } from "./message";
import type { IProduct } from "./product";
import type { Veterinarian } from "./veterinarian";

export type JournalStatus = {
  report?: string;
  shortStatus: string;
  status: string;
  sentTo: string;
  sentAt: string;
};
export interface JournalGroupMeta {
  sentToNorfor?: boolean;
  sentToPrevet?: boolean;
  sentToGod?: boolean;
  [key: string]: string | DateTime | boolean | undefined;
}

export type GeneratedReport = {
  id: number;
  preliminary: boolean;
  updatedAt: string;
  fileName: string;
};

export interface JournalGroup<T extends Journal = Journal> {
  id?: number;
  loaded?: boolean;
  journals?: T[];
  // status?: JournalStatus;
  customerId?: number;
  customer?: Customer;
  journalGroupNumber?: string;
  note?: string;
  notes?: Message[];
  customerNote?: string;
  customerNotes?: Message[];
  followup?: boolean;
  followupJournal?: string;
  productFamilyId?: number;
  productId?: number;
  invoice?: { [code: string]: boolean };
  force?: { [code: string]: boolean };
  productType?: IProduct;
  mailJobs?: MailJob[];
  collectedAt?: string;
  meta?: JournalGroupMeta;
  veterinarianId?: number;
  veterinarian?: Veterinarian;
  deleted?: boolean;
  invoiceGroups?: { id: number; code: string }[];
  invoiceBasisId?: number;
  quickPackageId?: number;
  blockSend?: boolean;
}

export type RegistrationJournalGroup = JournalGroup<RegistrationJournal>;
export type ResultJournalGroup = JournalGroup<ResultJournal>;

export function createJournalGroup<T extends Journal>(
  journalGroup?: JournalGroup<T>,
): JournalGroup<T> {
  const journals = journalGroup?.journals?.map((j) => createJournal(j));
  return { ...journalGroup, journals };
}

export function isValid(journalGroup: RegistrationJournalGroup) {
  return (
    !!journalGroup.journals?.length &&
    !journalGroup.journals?.some((j) => !isValidJournal(j))
  );
}

export function readMeta<K extends keyof JournalGroupMeta>(
  journalGroup: JournalGroup,
  key: K,
): JournalGroupMeta[K] {
  if (journalGroup.meta?.[key]) {
    return journalGroup.meta[key];
  }
  return undefined;
}
