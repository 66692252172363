import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["energy"] as const;
export const omsEnergyIdis = createFormula(
  dependencies,
  (analysis, { energy }, valid, blocked) => {
    if (!energy?.raw) {
      return { value: 0 };
    }
    return { value: +energy.raw || 0, blocked };
  },
);
