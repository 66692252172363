import {
  type PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import journalApi from "../apis/journalApi";
import registrationApi from "../apis/registrationApi";
import { getMailJobs } from "../helpers/mailJobHelper";
import type { RegistrationStep } from "../helpers/registrationStepHelper";
import {
  type AnalysisGroup,
  type IAnalysisGroup,
  invoice,
  selected,
} from "../models/analysisGroup";
import { createAnalysisGroupInstance } from "../models/analysisGroupInstance";
import type { Animal } from "../models/animal";
import type { Customer } from "../models/customer";
import { type JournalMeta, createJournal } from "../models/journal";
import type {
  RegistrationJournalGroup as JournalGroup,
  JournalGroupMeta,
} from "../models/journalGroup";
import type { MailJob } from "../models/mailJob";
import type { LabnumberValidationResult } from "../models/pocos/labnumberValidationResult";
import type { IProduct } from "../models/product";
import {
  type IProductFamily,
  readProductFamilyMeta,
} from "../models/productFamily";
import type { ProductPackage } from "../models/productPackage";
import type { Veterinarian } from "../models/veterinarian";
import { saveCustomer } from "./customerSlice";
import { addError, addSuccess } from "./notificationSlice";
import type { ProductsSliceState } from "./productsSlice";
import { createVeterinarian, updateVeterinarians } from "./veterinarianSlice";

export interface RegistrationSliceState {
  selectedProductFamilyId?: number;
  selectedProductId?: number;
  journalGroup: JournalGroup;
  selectedProductAnalyses?: AnalysisGroup[];
  currentStep: RegistrationStep;
  autofills: { [key: string]: string[] };
  registrationId: number | undefined;
  saving: boolean;
  loading: boolean;
}

const initialState: RegistrationSliceState = {
  selectedProductFamilyId: undefined,
  selectedProductId: undefined,
  journalGroup: {},
  selectedProductAnalyses: [],
  currentStep: "analysis",
  autofills: {},
  registrationId: undefined,
  saving: false,
  loading: false,
};

type ThunkApi = {
  state: { registration: RegistrationSliceState; products: ProductsSliceState };
};

export const fetchAutofills = createAsyncThunk<void, IProductFamily, ThunkApi>(
  "registration/fetchAutofills",
  async (productFamily: IProductFamily, thunkApi) => {
    const fields = readProductFamilyMeta(productFamily, "fields") ?? {};
    const fetchPromises = [];
    for (const [key, field] of Object.entries(fields)) {
      if (field.type === "text" && field.autocompleteFrom) {
        fetchPromises.push(
          thunkApi.dispatch(
            fetchAutofill({ key, path: field.autocompleteFrom }),
          ),
        );
      }
    }
    await Promise.all(fetchPromises);
  },
);

const fetchAutofill = createAsyncThunk<
  { key: string; content: string[] },
  { key: string; path: string },
  ThunkApi
>(
  "registration/fetchAutofill",
  async (payload: { key: string; path: string }, thunkApi) => {
    const state = thunkApi.getState();
    if (!state.registration.autofills[payload.key]) {
      return {
        key: payload.key,
        content: await registrationApi.fetchAutofill(payload.path),
      };
    } else {
      return {
        key: payload.key,
        content: state.registration.autofills[payload.key],
      };
    }
  },
);

export const saveAndSetCustomer = createAsyncThunk<
  { customer: Customer },
  Customer,
  ThunkApi
>("registration/saveAndSetCustomer", async (customer, thunkApi) => {
  const updatedCustomerAction = await thunkApi.dispatch(saveCustomer(customer));
  const updatedCustomer = (
    updatedCustomerAction.payload as { customer: Customer }
  ).customer;
  await thunkApi.dispatch(setCustomer(updatedCustomer));
  return { customer: updatedCustomer };
});

export const setCustomer = createAsyncThunk<
  {
    customer: Customer;
    mailJobs: readonly MailJob[] | undefined;
  },
  Customer,
  ThunkApi
>("registration/setCustomer", async (customer, thunkApi) => {
  const state = thunkApi.getState();
  const selectedProduct = state.products.productFamilies.find(
    (pf) => pf.id === state.registration.selectedProductFamilyId,
  );
  const mailJobs =
    state.registration.journalGroup && selectedProduct
      ? getMailJobs(
          customer,
          state.registration.journalGroup,
          state.registration.journalGroup.veterinarian,
          selectedProduct,
          true,
        )
      : undefined;
  return { customer, mailJobs };
});

export const clearRegistration = createAsyncThunk<
  {
    product: IProduct | undefined;
    productAnalyses: AnalysisGroup[] | undefined;
    step: RegistrationStep | undefined;
  },
  RegistrationStep | undefined,
  ThunkApi
>("registration/clear", async (nextStep, thunkApi) => {
  const state = thunkApi.getState();
  const selectedProductFamilyId = state.registration.selectedProductFamilyId;
  const selectedProductId = state.registration.selectedProductId;
  if (
    selectedProductFamilyId === undefined ||
    selectedProductId === undefined
  ) {
    return { product: undefined, productAnalyses: undefined, step: nextStep };
  }
  const productFamily = state.products.products[selectedProductFamilyId];
  if (productFamily) {
    const product = productFamily.find((p) => p.id === selectedProductId);
    if (product) {
      const productAnalyses = state.products.productAnalyses[product.id];
      await thunkApi.dispatch(selectProduct(product));
      return { product, productAnalyses, step: nextStep };
    }
    return { product, productAnalyses: undefined, step: nextStep };
  }
  return { product: undefined, productAnalyses: undefined, step: nextStep };
});

export const loadJournal = createAsyncThunk<
  JournalGroup,
  | { journalId: number; labnumber?: never }
  | { labnumber: number; journalId?: never },
  ThunkApi
>("registration/load", async ({ labnumber, journalId }, thunkApi) => {
  let result: JournalGroup;
  if (labnumber) {
    result = await journalApi.loadRegistration(labnumber);
    const registrationId = await registrationApi.find(labnumber);
    thunkApi.dispatch(setRegistrationId(registrationId));
  } else if (journalId) {
    result = await journalApi.loadFromId(journalId);
  } else {
    return thunkApi.rejectWithValue("");
  }
  const journalGroup = result;
  const state = thunkApi.getState();
  const productFamily = state.products.productFamilies.find(
    (f) => f.id === journalGroup.productFamilyId,
  );
  if (!productFamily) {
    return thunkApi.rejectWithValue("");
  }
  thunkApi.dispatch(selectProductFamily(productFamily));
  const productType = journalGroup.productType;
  if (!productType) {
    return thunkApi.rejectWithValue("");
  }
  thunkApi.dispatch(selectProduct(productType));
  return journalGroup;
});

export const doSaveJournal = createAsyncThunk<
  { journalGroup: JournalGroup; clear: boolean },
  { clear: boolean; nextStep: RegistrationStep },
  ThunkApi
>("registration/save", async ({ clear, nextStep }, thunkApi) => {
  const state = thunkApi.getState();
  const journalGroup = state.registration.journalGroup;
  if (!journalGroup) {
    return thunkApi.rejectWithValue("");
  }
  try {
    const result = await registrationApi.create(journalGroup);
    const updatedJournalGroup = result;
    thunkApi.dispatch(
      addSuccess(
        `Journal med journalnummer ${updatedJournalGroup.journalGroupNumber} har sparats`,
      ),
    );
    const productFamily = state.products.productFamilies.find(
      (f) => f.id === journalGroup.productFamilyId,
    );
    if (clear) {
      thunkApi.dispatch(clearRegistration(nextStep));
    } else {
      if (!productFamily) {
        return thunkApi.rejectWithValue("");
      }
      thunkApi.dispatch(selectStep(nextStep));
    }
    return { journalGroup: updatedJournalGroup, clear: clear };
  } catch (error) {
    thunkApi.dispatch(addError("Journalen kunde inte sparats"));
    return thunkApi.rejectWithValue("");
  }
});

export const changeLabnumber = createAsyncThunk<
  { labnumber: number; journalId: number },
  { labnumber: number; journalId: number },
  ThunkApi
>("registration/changeLabnumber", async (arg, thunkApi) => {
  try {
    thunkApi.dispatch(validateLabnumber(arg));
    return arg;
  } catch (error) {
    return thunkApi.rejectWithValue("");
  }
});

const validateLabnumber = createAsyncThunk<
  { labnumber: number; journalId: number; valid: LabnumberValidationResult },
  { labnumber: number; journalId: number },
  ThunkApi
>("registration/validateLabnumber", async (arg, thunkApi) => {
  try {
    const valid = await journalApi.checkLabnumber(arg.labnumber);
    const state = thunkApi.getState();

    if (
      valid.free &&
      state.registration.journalGroup?.journals?.some(
        (j) => j.id !== arg.journalId && j.labnumber === arg.labnumber,
      )
    ) {
      return { ...arg, valid: { ...valid, free: false } };
    }
    return { ...arg, valid };
  } catch (error) {
    return thunkApi.rejectWithValue("");
  }
});

type PreregistrationParams = {
  journalGroup: JournalGroup;
  product: IProduct;
};

const selectInvoiceHelper = (
  state: RegistrationSliceState,
  analysisGroupId: number,
  value: boolean,
) => {
  let invoiceGroups = state.journalGroup.invoiceGroups || [];
  if (value) {
    invoiceGroups.push({ id: analysisGroupId, code: "" });
  } else {
    invoiceGroups = invoiceGroups.filter((ig) => ig.id !== analysisGroupId);
  }
  return invoiceGroups;
};

export const applyPreregistration = createAsyncThunk<
  JournalGroup,
  PreregistrationParams,
  ThunkApi
>(
  "registration/applyPreregistration",
  async (registration: PreregistrationParams, thunkApi) => {
    await thunkApi.dispatch(selectProduct(registration.product));
    return registration.journalGroup;
  },
);

export const selectProduct = createAsyncThunk<
  { product: IProduct; productAnalyses: AnalysisGroup[] },
  IProduct,
  ThunkApi
>("registration/selectProduct", (product: IProduct, thunkApi) => {
  const state = thunkApi.getState();
  const productAnalyses = state.products.productAnalyses[product.id];
  if (!productAnalyses) {
    return thunkApi.rejectWithValue("");
  }
  return { product, productAnalyses };
});

type MetaFieldChange<V, T extends keyof V = keyof V> = {
  fieldName: T;
  value: V[T];
};

let id = -1;
const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    selectProductFamily(state, action: PayloadAction<IProductFamily>) {
      state.selectedProductFamilyId = action.payload.id;
      state.journalGroup = {
        productFamilyId: action.payload.id,
      };
    },
    changeCollecedAt(state, action: PayloadAction<string | undefined>) {
      state.journalGroup.collectedAt = action.payload;
    },
    changeMetaField(
      state,
      action: PayloadAction<
        MetaFieldChange<JournalMeta> & { journalId: number }
      >,
    ) {
      const journal = state.journalGroup.journals?.find(
        (j) => j.id === action.payload.journalId,
      );
      if (journal) {
        const meta = journal?.meta ?? {};
        journal.meta = {
          ...meta,
          [action.payload.fieldName]: action.payload.value,
        };
      }
    },
    changeGroupMetaField(
      state,
      action: PayloadAction<MetaFieldChange<JournalGroupMeta>>,
    ) {
      let meta = state.journalGroup.meta ?? {};
      meta = { ...meta, [action.payload.fieldName]: action.payload.value };
      state.journalGroup.meta = meta;
    },
    addJournal(state) {
      if (!state.journalGroup.journals) {
        state.journalGroup.journals = [];
      }
      const sortOrders = state.journalGroup.journals?.map(
        (j) => j.meta?.sortOrder ?? 0,
      );
      const sortOrder =
        Math.max(...(sortOrders?.length ? sortOrders : [0])) + 1;
      state.journalGroup.journals.push(
        createJournal({
          id: id--,
          meta: { sortOrder },
          analysisGroupInstances:
            state.selectedProductAnalyses?.map((ag) =>
              createAnalysisGroupInstance({
                analysisGroupId: ag.id,
                selected: ag.selected,
              }),
            ) ?? [],
        }),
      );
    },
    deleteJournal(state, action: PayloadAction<{ journalId: number }>) {
      state.journalGroup.journals = state.journalGroup.journals?.filter(
        (journal) => journal.id !== action.payload.journalId,
      );
      if (state.journalGroup.journals?.length === 0) {
        state.journalGroup.journals.push(
          createJournal({
            id: id--,
            meta: { sortOrder: 1 },
            analysisGroupInstances:
              state.selectedProductAnalyses?.map((ag) =>
                createAnalysisGroupInstance({
                  analysisGroupId: ag.id,
                  selected: ag.selected,
                }),
              ) ?? [],
          }),
        );
      }
    },
    selectAnalysis(
      state,
      action: PayloadAction<{
        journalId: number;
        analysisId: number;
        value: boolean;
      }>,
    ) {
      const journal = state.journalGroup.journals?.find(
        (j) => j.id === action.payload.journalId,
      );
      if (journal) {
        let analysisGroup = journal.analysisGroupInstances.find(
          (ag) => ag.analysisGroupId === action.payload.analysisId,
        );
        if (!analysisGroup) {
          analysisGroup = createAnalysisGroupInstance({
            analysisGroupId: action.payload.analysisId,
            selected: action.payload.value,
          });
          journal.analysisGroupInstances.push(analysisGroup);
        } else {
          analysisGroup.selected = action.payload.value;
        }
      }
    },
    selectAnalysisAll(
      state,
      action: PayloadAction<{ analysisGroupId: number; value: boolean }>,
    ) {
      const selectedProductAnalyses = state.selectedProductAnalyses;
      if (selectedProductAnalyses) {
        const selectedProductAnalysis = selectedProductAnalyses.find(
          (ag) => ag.id === action.payload.analysisGroupId,
        );
        if (selectedProductAnalysis) {
          selectedProductAnalysis.selected = action.payload.value;
        }
        const journalGroup = state.journalGroup;
        if (journalGroup?.journals) {
          for (const journal of journalGroup.journals) {
            const analysisGroup = journal.analysisGroupInstances.find(
              (ag) => ag.analysisGroupId === action.payload.analysisGroupId,
            );
            if (analysisGroup) {
              analysisGroup.selected = action.payload.value;
            }
          }
        }
      }
    },
    selectProductPackage(
      state,
      action: PayloadAction<{ productPackage: ProductPackage }>,
    ) {
      const selectedProductAnalyses = state.selectedProductAnalyses;
      if (selectedProductAnalyses) {
        for (const ag of selectedProductAnalyses) {
          ag.selected = action.payload.productPackage.selectedAnalyses.includes(
            ag.id,
          );
          const invoice =
            (action.payload.productPackage.invoice?.indexOf(ag.id) ?? -1) > -1;
          state.journalGroup.quickPackageId = action.payload.productPackage.id;
          state.journalGroup.invoiceGroups = selectInvoiceHelper(
            state,
            ag.id,
            !ag.selected || invoice,
          );
        }

        const journalGroup = state.journalGroup;
        if (journalGroup?.journals) {
          for (const journal of journalGroup.journals) {
            for (const analysisGroup of journal.analysisGroupInstances) {
              analysisGroup.selected =
                action.payload.productPackage.selectedAnalyses.includes(
                  analysisGroup.analysisGroupId,
                );
            }
          }
        }
      }
    },
    changeAnalysisGroup(
      state,
      action: PayloadAction<{
        journalId: number;
        analysisGroupId: number;
        groupNumber: number | undefined;
      }>,
    ) {
      const journal = state.journalGroup.journals?.find(
        (j) => j.id === action.payload.journalId,
      );
      if (journal) {
        let analysisGroup = journal.analysisGroupInstances.find(
          (ag) => ag.analysisGroupId === action.payload.analysisGroupId,
        );
        if (!analysisGroup) {
          analysisGroup = createAnalysisGroupInstance({
            analysisGroupId: action.payload.analysisGroupId,
            groupnr: action.payload.groupNumber,
          });
          journal.analysisGroupInstances.push(analysisGroup);
        } else {
          analysisGroup.groupnr = action.payload.groupNumber;
        }
      }
    },
    selectAnalysisInvoice(
      state,
      action: PayloadAction<{ analysisGroupId: number; value: boolean }>,
    ) {
      state.journalGroup.invoiceGroups = selectInvoiceHelper(
        state,
        action.payload.analysisGroupId,
        action.payload.value,
      );
    },
    selectStep(state, action: PayloadAction<RegistrationStep>) {
      state.currentStep = action.payload;
    },
    setInternalNote(state, action: PayloadAction<string>) {
      state.journalGroup.note = action.payload;
    },
    setCustomerNote(state, action: PayloadAction<string>) {
      state.journalGroup.customerNote = action.payload;
    },
    creatingCustomer(state, action: PayloadAction<Customer>) {
      state.journalGroup.customerId = action.payload.id;
    },
    addMailJob(state, action: PayloadAction<MailJob>) {
      if (state.journalGroup.mailJobs === undefined) {
        state.journalGroup.mailJobs = [];
      }
      state.journalGroup.mailJobs.push(action.payload);
    },
    selectMailJob(state, action: PayloadAction<MailJob>) {
      if (state.journalGroup.mailJobs) {
        const index = state.journalGroup?.mailJobs?.findIndex(
          (j) => j.email === action.payload.email,
        );
        if (index !== undefined && index >= 0) {
          state.journalGroup.mailJobs[index] = action.payload;
        }
      }
    },
    changeIdentifier(
      state,
      action: PayloadAction<{ identifier: string; journalId: number }>,
    ) {
      const journal = state.journalGroup.journals?.find(
        (j) => j.id === action.payload.journalId,
      );
      if (journal) {
        journal.identifier = action.payload.identifier;
      }
    },
    selectAnimal(
      state,
      action: PayloadAction<{ animal: Animal | undefined; journalId: number }>,
    ) {
      const journal = state.journalGroup.journals?.find(
        (j) => j.id === action.payload.journalId,
      );
      if (journal) {
        journal.identifier = action.payload.animal?.name;
        journal.animal = action.payload.animal;
        journal.animalId = action.payload.animal?.id;
        if (action.payload.animal?.meta) {
          journal.meta = {
            ...(journal?.meta ?? {}),
            ...action.payload.animal.meta,
          };
        }
      }
    },
    selectVeterinarian(state, action: PayloadAction<Veterinarian | undefined>) {
      state.journalGroup.veterinarian = action.payload;
      state.journalGroup.veterinarianId = action.payload?.id;
    },
    setRegistrationId(state, action: PayloadAction<number | undefined>) {
      state.registrationId = action.payload;
    },
    setFollowup(state, action: PayloadAction<boolean>) {
      state.journalGroup.followup = action.payload;
    },
    setFollowupJournal(state, action: PayloadAction<string>) {
      state.journalGroup.followupJournal = action.payload;
    },
    addExtra(state, action: PayloadAction<number>) {
      if (!state.selectedProductAnalyses) {
        return;
      }
      const index = state.selectedProductAnalyses.findIndex(
        (analysisGroup) => analysisGroup.id === action.payload,
      );
      if (index >= 0) {
        const analysisGroup = {
          ...state.selectedProductAnalyses[index],
          extra: false,
        };
        state.selectedProductAnalyses[index] = analysisGroup;
      }
    },
    markNoteForDeletion(state, action: PayloadAction<number>) {
      const note =
        state.journalGroup.notes?.find((n) => n.id === action.payload) ??
        state.journalGroup.customerNotes?.find((n) => n.id === action.payload);
      if (note) {
        note.delete = true;
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchAutofill.fulfilled, (state, action) => {
        state.autofills[action.payload.key] = action.payload.content;
      })
      .addCase(createVeterinarian.fulfilled, (state, action) => {
        state.journalGroup.veterinarian = action.payload;
        state.journalGroup.veterinarianId = action.payload?.id;
      })
      .addCase(updateVeterinarians.fulfilled, (state, action) => {
        state.journalGroup.veterinarian = action.payload;
        state.journalGroup.veterinarianId = action.payload?.id;
      })
      .addCase(setCustomer.fulfilled, (state, action) => {
        state.journalGroup.customer = action.payload.customer;
        state.journalGroup.customerId = action.payload.customer.id;
        state.journalGroup.mailJobs = action.payload.mailJobs?.slice();
      })
      .addCase(selectProduct.fulfilled, (state, action) => {
        state.journalGroup.productId = action.payload.product.id;
        state.journalGroup.invoiceGroups = createInvoiceGroups(
          action.payload.productAnalyses,
          action.payload.product,
        );
        if ((state.journalGroup.journals ?? []).length === 0) {
          state.journalGroup.journals = [
            createJournal({
              id: id--,
              analysisGroupInstances: action.payload.productAnalyses.map((ag) =>
                createAnalysisGroupInstance({
                  analysisGroupId: ag.id,
                  selected: selected(ag, action.payload.product),
                  invoice: invoice(ag, action.payload.product),
                }),
              ),
            }),
          ];
        } else {
          for (const journal of state.journalGroup.journals ?? []) {
            journal.analysisGroupInstances = action.payload.productAnalyses.map(
              (ag) =>
                createAnalysisGroupInstance({
                  analysisGroupId: ag.id,
                  selected: selected(ag, action.payload.product),
                  invoice: invoice(ag, action.payload.product),
                }),
            );
          }
        }
        state.selectedProductId = action.payload.product.id;
        state.selectedProductAnalyses = action.payload.productAnalyses.map(
          (ag) => ({ ...ag, selected: selected(ag, action.payload.product) }),
        );
      })
      .addCase(applyPreregistration.fulfilled, (state, action) => {
        state.journalGroup = action.payload;
        state.selectedProductFamilyId = action.payload.productFamilyId;
      })
      .addCase(clearRegistration.fulfilled, (state, action) => {
        state.journalGroup = {
          productFamilyId: state.selectedProductFamilyId,
          productId: state.selectedProductId,
        };
        if (action.payload.step) {
          state.currentStep = action.payload.step;
        }
        const product = action.payload.product;
        if (product && action.payload.productAnalyses) {
          state.journalGroup.invoiceGroups = createInvoiceGroups(
            action.payload.productAnalyses,
            product,
          );
          state.journalGroup.journals = [
            createJournal({
              id: id--,
              analysisGroupInstances: action.payload.productAnalyses.map((ag) =>
                createAnalysisGroupInstance({
                  analysisGroupId: ag.id,
                  selected: selected(ag, product),
                  invoice: invoice(ag, product),
                }),
              ),
            }),
          ];
          state.selectedProductId = product.id;
          state.selectedProductAnalyses = action.payload.productAnalyses.map(
            (ag) => ({ ...ag, selected: selected(ag, product) }),
          );
        }
        state.registrationId = undefined;
      })
      .addCase(doSaveJournal.pending, (state) => {
        state.saving = true;
      })
      .addCase(doSaveJournal.rejected, (state) => {
        state.saving = false;
      })
      .addCase(doSaveJournal.fulfilled, (state, action) => {
        if (!action.payload.clear) {
          state.journalGroup = action.payload.journalGroup;
        }
        if (state.journalGroup.journals) {
          state.journalGroup.journals.sort(
            (a, b) =>
              (a.meta?.sortOrder &&
                b.meta?.sortOrder &&
                a.meta.sortOrder - b.meta.sortOrder) ||
              a.id - b.id,
          );
          state.saving = false;
        }
      })
      .addCase(loadJournal.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadJournal.rejected, (state) => {
        state.loading = false;
      })
      .addCase(loadJournal.fulfilled, (state, action) => {
        state.journalGroup = { ...action.payload };
        if (state.journalGroup.journals) {
          state.journalGroup.journals.sort(
            (a, b) =>
              (a.meta?.sortOrder &&
                b.meta?.sortOrder &&
                a.meta.sortOrder - b.meta.sortOrder) ||
              a.id - b.id,
          );
          state.selectedProductAnalyses = state.selectedProductAnalyses?.map(
            (ag) => {
              const isSelected =
                ag.selected ||
                !!state.journalGroup.journals?.find(
                  (j) =>
                    j.analysisGroupInstances.find(
                      (agi) => agi.analysisGroupId === ag.id,
                    )?.selected,
                );
              return {
                ...ag,
                selected: isSelected,
                extra: ag.extra && !isSelected,
              };
            },
          );
        }
        state.loading = false;
      })
      .addCase(changeLabnumber.fulfilled, (state, action) => {
        const journal = state.journalGroup.journals?.find(
          (j) => j.id === action.payload.journalId,
        );
        if (journal) {
          journal.labnumber = action.payload.labnumber;
        }
      })
      .addCase(validateLabnumber.fulfilled, (state, action) => {
        const journal = state.journalGroup.journals?.find(
          (j) => j.id === action.payload.journalId,
        );
        if (journal) {
          if (action.payload.labnumber === journal.labnumber) {
            journal.validLabnumber = action.payload.valid.free;
            state.registrationId = action.payload.valid.registration;
          }
        }
      }),
});

export const {
  selectProductFamily,
  changeCollecedAt,
  changeMetaField,
  changeGroupMetaField,
  changeIdentifier,
  selectAnalysis,
  selectAnalysisAll,
  changeAnalysisGroup,
  selectAnalysisInvoice,
  selectStep,
  setInternalNote,
  setCustomerNote,
  creatingCustomer,
  selectMailJob,
  addMailJob,
  deleteJournal,
  selectAnimal,
  selectVeterinarian,
  setFollowup,
  setFollowupJournal,
  addExtra,
  addJournal,
  markNoteForDeletion,
  selectProductPackage,
} = registrationSlice.actions;

const { setRegistrationId } = registrationSlice.actions;

export default registrationSlice.reducer;

function createInvoiceGroups(
  productAnalyses: Readonly<IAnalysisGroup>[],
  product: IProduct,
): { id: number; code: string }[] {
  return productAnalyses
    .filter((pa) => invoice(pa, product))
    .map((pa) => {
      return { id: pa.id, code: pa.code };
    });
}
