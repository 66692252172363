import { hasOwnProperty } from "../helpers/utils";
import type { Analysis, IAnalysis } from "../models/analysis";
type FormulaResult = {
  value: number;
  blocked?: boolean;
  minlimit?: boolean;
};

type RawFormula<T extends readonly string[]> = (
  analysis: IAnalysis,
  analyses: { [K in T[number]]: IAnalysis | undefined },
  valid: boolean,
  blocked: boolean,
) => FormulaResult;

type FormulaOptions = {
  optionalDependencies?: boolean;
};

export type Formula<T extends readonly string[] = readonly string[]> = {
  formula: RawFormula<T>;
  dependencies: T;
  dependenciesLookup?: (
    analysis: Analysis,
    analyses: readonly Analysis[],
  ) => Partial<{
    [K in T[number]]: IAnalysis | undefined;
  }>;
  formulaOptions?: FormulaOptions;
};

export const fromFormulaName = (
  analyses: readonly Analysis[],
  formulaName: string,
) => {
  return analyses.find(
    (analysis) => analysis.meta && analysis.meta.formulaName === formulaName,
  );
};

export const createFormula = <T extends readonly string[]>(
  dependencies: Formula<T>["dependencies"],
  formula: Formula<T>["formula"],
  dependenciesLookup?: Formula<T>["dependenciesLookup"],
  formulaOptions?: FormulaOptions,
): Formula<T> => ({
  dependencies,
  formula,
  dependenciesLookup,
  formulaOptions,
});

export function blocking(list: (undefined | Analysis | FormulaResult)[]) {
  return list.some((element) => {
    if (!element) {
      return true;
    }
    if (hasOwnProperty(element, "details")) {
      return !!(<Analysis>element).details?.blocked;
    } else {
      return !!(<FormulaResult>element).blocked;
    }
  });
}

export function validate(
  analysis: Analysis,
  analyses: readonly (Analysis | undefined)[],
) {
  if (analysis.raw == null) {
    return false;
  }
  return !analyses.find((a) => a?.raw == null);
}
