import { createFormula } from "./analysisFormulaUtils";
import { smbCarbConstants } from "../helpers/analysisFormulaConstants";

const dependencies = ["energy", "protein"] as const;
export const smbCarbGrov = createFormula(
  dependencies,
  (analysis, { energy, protein }, valid, blocked) => {
    if (!energy?.raw || !protein?.raw || !analysis.productMeta?.material) {
      return { value: 0 };
    }
    const magicConstants = smbCarbConstants(
      analysis.productMeta.material.toString(),
    );
    const smbKoeff = (magicConstants.a + magicConstants.b * energy.raw) / 100;
    const carbs = magicConstants.c - protein.raw * 1.46;
    return { value: smbKoeff * carbs, blocked };
  },
);
