import { createFormula, fromFormulaName } from "./analysisFormulaUtils";

const dependencies = ["vattenhalt", "percentStuff"] as const;

export const drysubst = createFormula(
  dependencies,
  (analysis, { vattenhalt, percentStuff }, valid, blocked) => {
    if (
      !vattenhalt?.raw ||
      !percentStuff?.raw ||
      vattenhalt.raw === percentStuff.raw
    ) {
      return { value: 0, blocked };
    }
    const dry = 100 - vattenhalt.raw;
    return {
      value: (percentStuff.raw / dry) * 100,
      blocked,
    };
  },
  (analysis, analyses) => {
    const percentStuff = fromFormulaName(
      analyses,
      <string>analysis.meta?.base_formula_name,
    );
    return { percentStuff };
  },
);
