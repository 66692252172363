import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["K", "Na", "cl", "S"] as const;

export const cab = createFormula(dependencies, (analysis, { K, Na, cl, S }) => {
  if (!K?.raw || !Na?.raw || !cl?.raw || !S?.raw) {
    return { value: 0 };
  }
  return {
    value:
      (K.raw / 1000 / 39.1 +
        Na.raw / 1000 / 23 -
        (cl.raw / 35.5 + ((S.raw / 1000) * 2) / 32)) *
      1000,
  };
});
