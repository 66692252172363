import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["protein", "smbCarbGrov"] as const;

export const aatGrov = createFormula(
  dependencies,
  (analysis, { protein, smbCarbGrov }, valid, blocked) => {
    if (!protein?.raw || !smbCarbGrov?.calc) {
      return { value: 0 };
    }
    const EPD = 0.8;
    const a = 0.65;
    const b = 0.82;
    const c = 0.179;
    const d = 0.7;
    const e = 0.85;
    return {
      value: protein.raw * (1 - EPD) * a * b + +smbCarbGrov.calc * c * d * e,
      blocked,
    };
  },
);
