import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import veterinarianApi from "../apis/veterinarianApi";
import type { Veterinarian } from "../models/veterinarian";

export interface VeterinarianSliceState {
  veterinarians: Veterinarian[];
  saving: boolean;
}

const initialState: VeterinarianSliceState = {
  veterinarians: [],
  saving: false,
};

export const loadVeterinarians = createAsyncThunk(
  "veterinarians/load",
  async () => {
    return veterinarianApi.load();
  },
);

export const createVeterinarian = createAsyncThunk(
  "veterinarians/create",
  async (params: Veterinarian) => {
    return veterinarianApi.create(params);
  },
);

export const updateVeterinarians = createAsyncThunk(
  "veterinarians/update",
  async (params: Veterinarian) => {
    return veterinarianApi.update(params);
  },
);

const veterinarianSlice = createSlice({
  name: "veterinarian",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(loadVeterinarians.fulfilled, (state, action) => {
        const veterinarians = action.payload.map((veterinarian) => ({
          ...veterinarian,
          number: veterinarian.number?.toString().padStart(4, "0"),
        }));
        state.veterinarians = veterinarians;
      })
      .addCase(createVeterinarian.fulfilled, (state, action) => {
        const veterinarian = action.payload;
        state.veterinarians.push({
          ...veterinarian,
          number: veterinarian.number?.toString().padStart(4, "0"),
        });
      })
      .addCase(updateVeterinarians.fulfilled, (state, action) => {
        const veterinarian = action.payload;
        const index = state.veterinarians.findIndex(
          (v) => v.id === veterinarian.id,
        );
        state.veterinarians[index] = {
          ...veterinarian,
          number: veterinarian.number?.toString().padStart(4, "0"),
        };
      }),
});

export default veterinarianSlice.reducer;
