import type { Theme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    orange: Palette["primary"];
  }

  interface PaletteOptions {
    orange?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    orange: true;
  }
}

export const addOrange = (theme: Theme) =>
  theme.palette.augmentColor({
    color: { main: "#ff9100" },
    name: "orange",
  });
