import {
  AdminPanelSettings,
  AltRoute,
  Analytics,
  Biotech,
  BlurCircular,
  Delete,
  EditNote,
  Email,
  Gesture,
  Grass,
  Group,
  List,
  Pause,
  Person,
  Receipt,
  Search,
  Speed,
  Storm,
  type SvgIconComponent,
} from "@mui/icons-material";
import type { RouteObject } from "react-router";
import { Antibody, Genetic } from "./customIcons";

export type MenuItem = {
  name: string;
  link: string;
  param?: string;
  sub?: string;
  label: string;
  hidden?: boolean;
  active?: boolean;
  icon: SvgIconComponent;
  file: () => Promise<unknown>;
  children?: MenuItem[];
  permission?: string;
};

export const menuItems: MenuItem[] = [
  {
    name: "dashboard",
    link: "/t/dashboard",
    label: "Dashboard",
    icon: Speed,
    file: () => import("../views/dashboard/dashboard"),
    hidden: false,
  },
  {
    name: "results",
    link: "/t/results",
    label: "Resultat",
    icon: Search,
    file: () => import("../views/results/resultsView"),
  },
  {
    name: "todo",
    link: "/t/todo",
    label: "Listor",
    icon: List,
    file: () => import("../views/lists/lists"),
  },
  {
    name: "customer",
    link: "/t/customer",
    sub: "customers",
    label: "Kunder",
    icon: Person,
    file: () => import("../views/customer/customerSection"),
    children: [],
  },
  {
    name: "lineCheck",
    link: "/t/lineCheck",
    label: "Linjekontroll",
    icon: AltRoute,
    file: () => import("../views/lineCheck/lineCheckView"),
  },
  {
    name: "cultivation",
    link: "/t/cultivation",
    sub: "start",
    label: "Odlingskontroll",
    icon: BlurCircular,
    file: async () => {
      const { OdlingContainer } = await import(
        "../views/cultivation/cultivationContainer"
      );
      return { Component: OdlingContainer };
    },
    children: [],
  },
  {
    name: "lungmask",
    link: "/t/lungmask",
    sub: "start",
    label: "Lungmaskkontroll",
    icon: Gesture,
    file: async () => {
      const { LungmaskContainer } = await import(
        "../views/cultivation/cultivationContainer"
      );
      return { Component: LungmaskContainer };
    },
    children: [],
  },
  {
    name: "read",
    link: "/t/read",
    label: "Avläsning",
    icon: Biotech,
    file: () => import("../views/readingFC"),
  },
  {
    name: "trikin2",
    link: "/t/trikin2",
    sub: "bubble",
    label: "Trikin",
    icon: Storm,
    file: () => import("../views/trikin/trikinContainer"),
    children: [],
  },
  {
    name: "pcr",
    link: "/t/pcr",
    sub: "check",
    label: "PCR",
    icon: Genetic,
    file: () => import("../views/pcr/pcrContainer"),
    children: [],
  },
  {
    name: "elisa",
    link: "/t/elisa",
    sub: "check",
    label: "ELISA",
    icon: Antibody,
    file: () => import("../views/elisa/elisaContainer"),
    children: [],
  },
  {
    name: "disposal",
    link: "/t/disposal",
    sub: "dispose",
    label: "Slängkontroll",
    icon: Delete,
    file: () => import("../views/lineCheck/disposalContainer"),
    children: [],
  },
  {
    name: "feed",
    link: "/t/feed",
    sub: "check",
    label: "Foder",
    icon: Grass,
    file: () => import("../views/feedContainer"),
    children: [],
  },
  {
    name: "registration",
    link: "/t/registration",
    param: "journalId?",
    label: "Inregistrering",
    icon: EditNote,
    file: () => import("../views/registrationContainer"),
  },
  {
    name: "email",
    link: "/t/email",
    label: "Epost",
    icon: Email,
    file: () => import("../views/emailStatus/emailStatus"),
  },
  {
    name: "invoice2",
    link: "/t/invoice2",
    sub: "invoice",
    label: "Fakturering",
    icon: Receipt,
    hidden: false,
    file: () => import("../views/invoice/invoiceContainer"),
    children: [],
  },
  {
    name: "statistics",
    link: "/t/statistics",
    sub: "statistics",
    label: "Statistik",
    icon: Analytics,
    file: () => import("../views/statistics/statisticsContainer"),
    children: [],
  },
  {
    name: "unfinished",
    link: "/t/unfinished",
    label: "Paus (BETA)",
    icon: Pause,
    file: () => import("../views/unfinished/unfinished"),
  },
  {
    name: "users",
    link: "/t/users",
    label: "Användare",
    icon: Person,
    file: () => import("../views/management/users"),
    permission: "admin",
    hidden: true,
  },
  {
    name: "admin",
    link: "/t/admin/productViewer",
    label: "Administration",
    icon: AdminPanelSettings,
    file: () => import("../views/admin/productViewer"),
    permission: "admin",
    hidden: false,
  },
  {
    name: "customerDuplicates",
    link: "/t/customerDuplicates",
    label: "Dubletter",
    icon: Group,
    file: () => import("../views/customerComparer/customerComparer"),
    hidden: true,
  },
];

export const browserItems = () => {
  return menuItems.map((item) => {
    let link = item.link;
    if (item.param) {
      link = `${link}/:${item.param}`;
    }
    if (item.children) {
      link = `${link}/*`;
    }
    return { path: link, lazy: item.file } as RouteObject;
  });
};
