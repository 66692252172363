import { createFormula } from "./analysisFormulaUtils";

const dependencies = [] as const;
export const spaceweight = createFormula(dependencies, (analysis) => {
  const cupWeight = 50;
  if (!analysis.raw || analysis.raw < cupWeight) {
    return { value: 0 };
  }
  const volWeight = analysis.raw - cupWeight;
  return { value: volWeight / 0.205 };
});
