import { createFormula } from "./analysisFormulaUtils";

const dependencies = [
  "glucose",
  "fructose",
  "fructan",
  "sucrose",
  "sucroseCorr",
] as const;

const sucroseCorrDependencies = [
  "sugar",
  "glucose",
  "fructose",
  "fructan",
  "sucrose",
] as const;

const sumPos = (arr: (number | undefined)[]) =>
  (<number[]>arr.filter((n) => n && n > 0)).reduce((p, c) => +p + +c, 0);

export const sucroseCorr = createFormula(
  sucroseCorrDependencies,
  (
    analysis,
    { sugar, glucose, fructose, fructan, sucrose },
    valid,
    blocked,
  ) => {
    if (
      sugar?.raw === undefined ||
      glucose?.raw === undefined ||
      fructose?.raw === undefined ||
      fructan?.raw === undefined ||
      sucrose?.raw === undefined
    ) {
      return { value: 0 };
    }
    return {
      value:
        (+glucose.raw +
          +fructose.raw +
          +fructan.raw +
          +sucrose?.raw -
          +sugar.raw) /
        2,
      blocked,
    };
  },
  undefined,
  { optionalDependencies: true },
);
const sugarDependencies = ["sucroseCorr"] as const;
export const sugar = createFormula(
  sugarDependencies,
  (sugar, { sucroseCorr }, valid, blocked) => {
    if (sugar?.raw === undefined) {
      return { value: 0 };
    }

    return {
      value: +sugar.raw - (sucroseCorr?.calc ?? 0),
      blocked: false, //blocked || sucroseC.blocked,
    };
  },
);

export const glucose = createFormula(
  dependencies,
  (glucose, { fructose, fructan, sucrose, sucroseCorr }, valid, blocked) => {
    if (glucose?.raw === undefined || sucroseCorr?.calc == null) {
      return { value: 0 };
    }
    const sum = sumPos([
      glucose.raw,
      fructose?.raw,
      fructan?.raw,
      sucrose?.raw,
    ]);
    if (sum === 0) {
      return { value: 0 };
    }
    return {
      value: +glucose.raw - sucroseCorr?.calc * (+glucose.raw / sum),
      blocked: blocked,
    };
  },
);

export const fructose = createFormula(
  dependencies,
  (fructose, { sucroseCorr, glucose, fructan, sucrose }, valid, blocked) => {
    if (fructose?.raw === undefined || sucroseCorr?.calc == null) {
      return { value: 0 };
    }
    const sum = sumPos([
      glucose?.raw,
      fructose?.raw,
      fructan?.raw,
      sucrose?.raw,
    ]);
    if (sum === 0) {
      return { value: 0 };
    }
    return {
      value: +fructose.raw - sucroseCorr.calc * (+fructose.raw / sum),
      blocked: blocked,
    };
  },
);

export const fructan = createFormula(
  dependencies,
  (fructan, { sucroseCorr, glucose, fructose, sucrose }, valid, blocked) => {
    if (fructan?.raw === undefined || sucroseCorr?.calc == null) {
      return { value: 0 };
    }
    const sum = sumPos([
      glucose?.raw,
      fructose?.raw,
      fructan?.raw,
      sucrose?.raw,
    ]);
    if (sum === 0) {
      return { value: 0 };
    }
    return {
      value: +fructan.raw - sucroseCorr.calc * (+fructan.raw / sum),
      blocked: blocked,
    };
  },
);

export const sucrose = createFormula(
  dependencies,
  (sucrose, { sucroseCorr, glucose, fructose, fructan }, valid, blocked) => {
    if (sucrose?.raw === undefined || sucroseCorr?.calc == null) {
      return { value: 0 };
    }
    const sum = sumPos([
      glucose?.raw,
      fructose?.raw,
      fructan?.raw,
      sucrose?.raw,
    ]);
    if (sum === 0) {
      return { value: 0 };
    }
    return {
      value: +sucrose.raw - sucroseCorr.calc * (+sucrose.raw / sum),
      blocked: blocked,
    };
  },
);
