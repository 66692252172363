import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["Ca", "P"] as const;
export const quotaCaP = createFormula(
  dependencies,
  (analysis, { Ca, P }, valid, blocked) => {
    if (!Ca?.raw || !P?.raw) {
      return { value: 0 };
    }
    return { value: Ca.raw / P.raw, blocked };
  },
);
