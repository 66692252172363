import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import checkApi from "../apis/checkApi";
import type { DisposeResult } from "../models/types/lineCheck";

export interface DisposalSliceState {
  disposing: boolean;
  disposeResult?: DisposeResult;
  checking: boolean;
  checkResult?: DisposeResult;
}

const initialState: DisposalSliceState = {
  disposing: false,
  checking: false,
};

export const setBagNumber = createAsyncThunk(
  "disposal/setBag",
  async (arg: { labnumber: number; bagnumber: number }) => {
    return checkApi.setBagNumber(arg.labnumber, arg.bagnumber);
  },
);

export const findBagNumber = createAsyncThunk(
  "disposal/find",
  async (labnumber: number) => {
    return checkApi.checkBagNumber(labnumber);
  },
);

const lineCheckSlice = createSlice({
  name: "disposal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setBagNumber.pending, (state) => {
        state.disposing = true;
      })
      .addCase(setBagNumber.fulfilled, (state, action) => {
        state.disposing = false;
        state.disposeResult = action.payload;
      })
      .addCase(setBagNumber.rejected, (state) => {
        state.disposing = false;
      })
      .addCase(findBagNumber.pending, (state) => {
        state.checking = true;
      })
      .addCase(findBagNumber.fulfilled, (state, action) => {
        state.checking = false;
        state.checkResult = action.payload;
      })
      .addCase(findBagNumber.rejected, (state) => {
        state.checking = false;
      });
  },
});

// export const {
// } = lineCheckSlice.actions;

export default lineCheckSlice.reducer;
