import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["empty", "dry", "wet"] as const;
export const dryWeight = createFormula(
  dependencies,
  (analysis, { empty, dry, wet }, valid, blocked) => {
    if (!empty?.raw || !dry?.raw || !wet?.raw || wet.raw === empty.raw) {
      return { value: 0 };
    }
    return {
      value: ((dry.raw - empty.raw) / (wet.raw - empty.raw)) * 100,
      blocked,
    };
  },
);

const dryWeightCorrDependencies = ["dryweight1"] as const;
export const dryWeightCorr = createFormula(
  dryWeightCorrDependencies,
  (analysis, { dryweight1 }, valid, blocked) => {
    if (!dryweight1?.calc) {
      return { value: 0, blocked };
    }
    const dryWeight2 = 0.95;

    let dryCorr = dryweight1.calc * dryWeight2;
    if (
      analysis.productMeta?.material?.toString().toLowerCase() === "ensilage"
    ) {
      dryCorr = 0.99 * dryCorr + 1;
    }
    return { value: dryCorr, blocked };
  },
);
