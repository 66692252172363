import type { ThemeOptions } from "@mui/material";
import { svSE } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import { svSE as gridSvSE } from "@mui/x-data-grid/locales";
import { svSE as dateSvSE } from "@mui/x-date-pickers/locales";
import { getPalette } from "../colors/colorHelper";

export const useTheme = () => {
  const palette: ThemeOptions["palette"] = {};
  if (window.location.hostname === "lims.jockeman.se") {
    palette.primary = {
      main: "#117511",
    };
  }
  const theme = createTheme(
    {
      colorSchemes: { dark: true },
      palette: palette,
      components: {
        MuiTextField: {
          defaultProps: {
            variant: "standard",
          },
        },
      },
    },
    gridSvSE,
    dateSvSE,
    svSE,
  );
  return createTheme(theme, { palette: getPalette(theme) });
};
