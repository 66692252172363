import type { Theme } from "@mui/material/styles";
import { addBlue } from "./blue";
import { addDarkGreen } from "./darkgreen";
import { addGreen } from "./green";
import { addIndigo } from "./indigo";
import { addOrange } from "./orange";
import { addOrchid } from "./orchid";
import { addPink } from "./pink";
import { addRed } from "./red";
import { addYellow } from "./yellow";

export type CustomColor = keyof ReturnType<typeof getPalette>;

export function getPalette(theme: Theme) {
  return {
    blue: addBlue(theme),
    green: addGreen(theme),
    yellow: addYellow(theme),
    red: addRed(theme),
    orchid: addOrchid(theme),
    orange: addOrange(theme),
    indigo: addIndigo(theme),
    pink: addPink(theme),
    darkgreen: addDarkGreen(theme),
  };
}

export function getColor(
  color: CustomColor | string | undefined,
  theme: Theme,
) {
  if (color && theme.palette[color as CustomColor]) {
    return theme.palette[color as CustomColor].main;
  }
  return color;
}

export function getContrastColor(
  color: CustomColor | string | undefined,
  theme: Theme,
) {
  const bgColor = getColor(color, theme);
  if (bgColor) {
    return theme.palette.getContrastText(bgColor);
  }
  return undefined;
}
