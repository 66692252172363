import type { Theme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    pink: Palette["primary"];
  }

  interface PaletteOptions {
    pink?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    pink: true;
  }
}

export const addPink = (theme: Theme) =>
  theme.palette.augmentColor({
    color: { main: "#FFC0CB" },
    name: "pink",
  });
