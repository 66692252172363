import {
  type PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import pauseApi from "../apis/pauseApi";
import type { DetailedJournalStatus } from "../models/detailedJournalStatus";

export interface PauseSliceState {
  journals: DetailedJournalStatus[];
  loading: boolean;
}

const initialState: PauseSliceState = {
  journals: [],
  loading: false,
};

export const fetchStatus = createAsyncThunk(
  "pause/fetchUnfinished",
  async () => {
    return pauseApi.unfinished();
  },
);

export const pauseAnalyses = createAsyncThunk(
  "pause/pause",
  async (labnumbers: readonly (string | number)[]) => {
    return pauseApi.pause(labnumbers);
  },
);

const pauseSlice = createSlice({
  name: "pause",
  initialState,
  reducers: {
    select(state, action: PayloadAction<number>) {
      const journal = state.journals.find(
        (j) => j.labnumber === action.payload,
      );
      if (journal) {
        journal.selected = !journal.selected;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchStatus.fulfilled, (state, action) => {
        state.journals = action.payload;
        state.loading = false;
      })
      .addCase(pauseAnalyses.pending, (state) => {
        state.loading = true;
      })
      .addCase(pauseAnalyses.rejected, (state) => {
        state.loading = false;
      })
      .addCase(pauseAnalyses.fulfilled, (state, action) => {
        state.journals = action.payload;
        state.loading = false;
      });
  },
});

// export const {} = pauseSlice.actions;

export default pauseSlice.reducer;
